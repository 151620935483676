import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  Fragment,
} from 'react';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FiArrowLeftCircle, FiArrowLeft } from 'react-icons/fi';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Text,
  Container,
  Image,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import queryString from 'query-string';

// import { User } from '~/@types/Users';
import logoImg from '~/assets/logo-form.png';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import { phoneMask } from '~/utils/mask';

interface HoldStructureFamily {
  id: number;
  user_id: number;
  civil_status_id: number;
  relative_id: number;
  name: string;
  age: number;
  created_at: string;
  updated_at: string;
}

interface HoldProperties {
  id: number;
  user_id: number;
  property_type_id: number;
  estimated_value: string;
  city: string;
  uf: string;
  owner_the_property: string;
  monthly_income_amount: string;
  created_at: string;
  updated_at: string;
}

interface HoldCompanies {
  id: string;
  user_id: number;
  tributary_regime_id: number;
  name: string;
  branch_activity: string;
  number_branches: number;
  monthly_billing: string;
  created_at: string;
  updated_at: string;
  address: string;
  number_employees: number;
}

interface User {
  id: number;
  name: string;
  email: string;
  phone: string;
  created_at: string;
  updated_at: string;
}

interface SurveyOptions {
  id: number;
  name: string;
  label: string;
  value: string;
  visible?: boolean;
}

interface Filters {
  relativies: SurveyOptions[];
  civil_status: SurveyOptions[];
  property_types: SurveyOptions[];
  tributary_regimes: SurveyOptions[];
}

interface UserSurvey extends User {
  survey: {
    id: number;
    name: string;
    token: string;
    description: string;
    created_at: string;
    updated_at: string;
  };
  hold_structure_family: HoldStructureFamily[];
  hold_properties: HoldProperties[];
  hold_companies: HoldCompanies[];
}

interface Params {
  userId: string;
}

const ViewUserSurvey: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingFilters, setLoadingFilters] = useState(true);

  const [survey, setSurvey] = useState<UserSurvey>();
  const [Users, setUsers] = useState<User>();
  const [family, setFamily] = useState<HoldStructureFamily[]>([]);
  const [properties, setProperties] = useState<HoldProperties[]>([]);
  const [companies, setCompanies] = useState<HoldCompanies[]>([]);
  const history = useHistory();

  const { userId } = useParams<Params>();

  const [relativiesTypes, setRelativieTypes] = useState<SurveyOptions[]>([]);
  const [civilStatus, setCivilStatus] = useState<SurveyOptions[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<SurveyOptions[]>([]);
  const [tributaryRegimeTypes, setTributaryRegimeTypes] = useState<
    SurveyOptions[]
  >([]);

  const location = useLocation();
  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  /*   const goLink = useMemo(() => {
    return `/questionarios/holding/usuarios?t=${queryParams.t}`;
  }, [queryParams.t]); */

  const getData = useCallback(() => {
    setLoadingData(true);
    api
      .get<UserSurvey>(`/survey/holding/users/${userId}`)
      .then((response) => {
        const { data } = response;
        const {
          created_at,
          email,
          id,
          name,
          phone,
          updated_at,
          hold_structure_family,
          hold_properties,
          hold_companies,
        } = data;

        setSurvey(data);
        setUsers({
          created_at: data.created_at,
          email: data.email,
          id: data.id,
          name: data.name,
          phone: data.phone,
          updated_at: data.updated_at,
        });
        setFamily(hold_structure_family);
        setProperties(hold_properties);
        setCompanies(hold_companies);
        // console.log(data.survey.token, data.id);
      })

      .finally(() => setLoadingData(false));
  }, [queryParams.t, userId]);

  const getSelect = useCallback(() => {
    setLoadingFilters(true);
    api
      .get<Filters>(`/survey/filters`)

      .then((response) => {
        const {
          civil_status,
          property_types,
          relativies,
          tributary_regimes,
        } = response.data;

        setRelativieTypes(
          relativies.map((rel) => {
            return { ...rel, label: rel.name, value: rel.id.toString() };
          }),
        );
        setPropertyTypes(
          property_types.map((pt) => {
            return { ...pt, label: pt.name, value: pt.id.toString() };
          }),
        );
        setCivilStatus(
          civil_status.map((cs) => {
            return { ...cs, label: cs.name, value: cs.id.toString() };
          }),
        );
        setTributaryRegimeTypes(
          tributary_regimes.map((tr) => {
            return { ...tr, label: tr.name, value: tr.id.toString() };
          }),
        );
      })
      .finally(() => setLoadingFilters(false));
  }, []);

  useEffect(() => {
    getSelect();
    getData();
  }, [getData, getSelect]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container pos="relative">
      {loadingData || loadingFilters ? (
        <LoadingAbsolute z_index={1111} min_height={500} />
      ) : (
        <>
          {!loadingData && !loadingFilters && (
            <>
              <Box display="flex" alignItems="center">
                <IconButton
                  aria-label="Voltar"
                  icon={<FiArrowLeft />}
                  size="lg"
                  borderRadius="md"
                  ml={3}
                  mt={3}
                  onClick={handleGoBack}
                  border="2px solid #4e545e"
                  // borderColor="black"
                />
                <Text ml={3} mt={3}>
                  Voltar
                </Text>
              </Box>

              <Box marginTop="20px">
                <Image
                  width="100%"
                  objectFit="contain"
                  src={logoImg}
                  alt="MyIPAC"
                  mb={10}
                />
                <Flex justifyContent="center">
                  <SectionHeader title={`Respondido por ${Users?.name}`} />
                </Flex>
              </Box>

              <Form ref={formRef} onSubmit={() => ''}>
                <Box>
                  {Users && (
                    <Container
                      backgroundColor="#fff"
                      border="1px solid rgb(218,220,224)"
                      borderRadius="8px"
                      position="relative"
                      paddingTop="22px"
                      padding="24px"
                      marginBottom="12px"
                      marginTop="12px"
                    >
                      <Flex justifyContent="center">
                        <Text marginBottom="10px" fontSize="4xl">
                          Dados pessoais
                        </Text>
                      </Flex>

                      <InputChakra
                        name="name"
                        label="Nome"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        value={Users.name}
                        readOnly
                      />
                      <InputChakra
                        name="email"
                        label="E-mail"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        value={Users.email}
                        readOnly
                      />
                      <InputChakra
                        name="phone"
                        label="Telefone"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        value={phoneMask(Users.phone)}
                        mask="phone"
                        readOnly
                      />
                    </Container>
                  )}

                  <Box>
                    {!!family.length &&
                      family.map((fam, idx) => {
                        const num = idx + 1;
                        return (
                          <Fragment key={num}>
                            <Container
                              backgroundColor="#fff"
                              border="1px solid rgb(218,220,224)"
                              borderRadius="8px"
                              position="relative"
                              paddingTop="22px"
                              padding="24px"
                              marginBottom="12px"
                              marginTop="12px"
                            >
                              {idx === 0 && (
                                <Flex justifyContent="center">
                                  <Text marginBottom="10px" fontSize="4xl">
                                    Estrutura Familiar
                                  </Text>
                                </Flex>
                              )}

                              <Text
                                color="#718096"
                                display="flex"
                                flexDirection="row-reverse"
                              >
                                {`Parente (${idx + 1})`}
                              </Text>

                              <InputChakra
                                name={`structure_family[${idx}].name`}
                                label="Nome"
                                value={fam.name}
                                background="#e9ecef"
                                borderColor=""
                                mb="0px"
                                readOnly
                              />
                              <SelectChakra
                                name={`structure_family[${idx}].relative_id`}
                                label="Parentesco"
                                options={relativiesTypes}
                                value={fam?.relative_id}
                                background="#e9ecef"
                                borderColor=""
                                mb="0px"
                                disabled
                              />

                              <SelectChakra
                                name={`structure_family[${idx}].civil_status_id`}
                                label="Estado Civil"
                                background="#e9ecef"
                                borderColor=""
                                mb="0px"
                                value={fam?.civil_status_id}
                                options={civilStatus}
                                placeholder="Selecione"
                                disabled
                              />

                              <InputChakra
                                name={`structure_family[${idx}].age`}
                                label="Idade"
                                background="#e9ecef"
                                borderColor=""
                                value={fam?.age}
                                mb="0px"
                                type="number"
                                readOnly
                              />
                            </Container>
                          </Fragment>
                        );
                      })}
                  </Box>

                  <Box>
                    {properties.map((proper, idx) => {
                      const num = idx + 1;
                      return (
                        <Fragment key={num}>
                          <Container
                            backgroundColor="#fff"
                            border="1px solid rgb(218,220,224)"
                            borderRadius="8px"
                            position="relative"
                            paddingTop="22px"
                            padding="24px"
                            marginBottom="12px"
                            marginTop="12px"
                          >
                            {idx === 0 && (
                              <Flex justifyContent="center">
                                <Text fontSize="4xl" marginBottom="10px">
                                  Imóveis
                                </Text>
                              </Flex>
                            )}

                            <Text
                              color="#718096"
                              display="flex"
                              flexDirection="row-reverse"
                            >
                              {`Imóvel (${idx + 1})`}
                            </Text>

                            <InputChakra
                              name={`properties[${idx}].owner_the_property`}
                              label="Proprietário do Bens (nome)"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              value={proper?.owner_the_property}
                              readOnly
                            />

                            <SelectChakra
                              name={`properties[${idx}].property_type_id`}
                              label="Descrição (tipo)"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              options={propertyTypes}
                              placeholder="Selecione"
                              value={proper?.property_type_id}
                              disabled
                            />

                            <InputChakra
                              name={`properties[${idx}].estimated_value`}
                              label="Valor Estimado "
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              // type="number"
                              value={proper?.estimated_value}
                              leftAddon="R$"
                              // mask="money"
                              maxLength={15}
                              readOnly
                            />
                            <InputChakra
                              name={`properties[${idx}].city`}
                              label="Localização (Cidade)"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              value={proper?.city}
                              readOnly
                            />
                            <InputChakra
                              name={`properties[${idx}].uf`}
                              label="UF"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              value={proper?.uf}
                              // placeholder="Selecione seu estado"
                              readOnly
                            />
                            <InputChakra
                              name={`properties[${idx}].monthly_income_amount`}
                              label="Faturamento Mensal"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              value={proper?.monthly_income_amount}
                              leftAddon="R$"
                              // mask="money"
                              // maxLength={15}
                              readOnly
                            />
                          </Container>
                        </Fragment>
                      );
                    })}
                  </Box>

                  <Box>
                    {companies.map((cp, idx) => {
                      const num = idx + 1;

                      return (
                        <Fragment key={num}>
                          <Container
                            backgroundColor="#fff"
                            border="1px solid rgb(218,220,224)"
                            borderRadius="8px"
                            position="relative"
                            paddingTop="22px"
                            padding="24px"
                            marginBottom="12px"
                            marginTop="12px"
                          >
                            {idx === 0 && (
                              <Flex justifyContent="center">
                                <Text fontSize="4xl" marginBottom="10px">
                                  Empresas
                                </Text>
                              </Flex>
                            )}

                            <Text
                              color="#718096"
                              display="flex"
                              flexDirection="row-reverse"
                            >
                              {`Empresa (${idx + 1})`}
                            </Text>

                            <InputChakra
                              name={`companies[${idx}].name`}
                              label="Nome da Empresa"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              value={cp?.name}
                              readOnly
                            />

                            <InputChakra
                              name={`companies[${idx}].branch_activity`}
                              label="Ramo de Atividade"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              value={cp?.branch_activity}
                              readOnly
                            />

                            <InputChakra
                              name={`companies[${idx}].address`}
                              label="Endereço"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              value={cp.address}
                              readOnly
                            />

                            <SelectChakra
                              name={`companies[${idx}].tributary_regime_id`}
                              label="Regime Tributário"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              options={tributaryRegimeTypes}
                              // placeholder="Selecione"
                              value={cp?.tributary_regime_id}
                              disabled
                            />

                            <InputChakra
                              name={`companies[${idx}].number_employees`}
                              label="Quantidade de Funcionários"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              type="number"
                              value={cp.number_employees}
                              readOnly
                            />

                            <InputChakra
                              name={`companies[${idx}].number_branches`}
                              label="Quantidade de Filiais"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              type="number"
                              value={cp?.number_branches}
                              readOnly
                            />
                            <InputChakra
                              name={`companies[${idx}].monthly_billing`}
                              label="Faturamento Mensal"
                              background="#e9ecef"
                              borderColor=""
                              mb="0px"
                              value={cp?.monthly_billing}
                              leftAddon="R$"
                              // mask="money"
                              // maxLength={15}
                              readOnly
                            />
                          </Container>
                        </Fragment>
                      );
                    })}
                  </Box>
                </Box>
              </Form>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ViewUserSurvey;
