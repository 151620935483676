import React from 'react';
import { Switch } from 'react-router-dom';

import AccountingChanging from '~/modules/AccountingChange';
import ConsultativeAnalysis from '~/modules/ConsultativeAnalysis';
import ListAccountingChanging from '~/modules/ListAccountingChanging';
import ListConsultativeAnalysis from '~/modules/ListConsultativeAnalysis';
import ListSurvey from '~/modules/ListSurvey';
import NotFound from '~/modules/NotFound';
import Survey from '~/modules/Survey';
import ViewAccountingChanging from '~/modules/ViewAccountingChanging';
import ViewConsultativeAnalysis from '~/modules/ViewConsultativeAnalysis';
import ViewUserSurvey from '~/modules/ViewUserSurvey';
import LayoutAuth from '~/shared/layouts/auth';
import LayoutDefault from '~/shared/layouts/default';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route
      path="/questionarios/analise-consultiva/usuarios/:userId"
      exact
      component={ViewConsultativeAnalysis}
      layout={LayoutAuth}
    />
    <Route
      path="/questionarios/analise-consultiva/usuarios"
      exact
      component={ListConsultativeAnalysis}
      layout={LayoutAuth}
    />
    <Route
      path="/questionarios/mudanca-de-contabilidade/usuarios/:userId"
      exact
      component={ViewAccountingChanging}
      layout={LayoutAuth}
    />
    <Route
      path="/questionarios/mudanca-de-contabilidade/usuarios"
      exact
      component={ListAccountingChanging}
      layout={LayoutAuth}
    />
    <Route
      path="/questionarios/mudanca-de-contabilidade"
      exact
      component={AccountingChanging}
      layout={LayoutAuth}
    />
    <Route
      path="/questionarios/analise-consultiva"
      exact
      component={ConsultativeAnalysis}
      layout={LayoutAuth}
    />
    <Route
      path="/questionarios/holding"
      exact
      component={Survey}
      layout={LayoutAuth}
    />
    <Route
      path="/questionarios/usuarios"
      exact
      component={ListSurvey}
      layout={LayoutAuth}
    />
    <Route
      path="/questionarios/holding/usuarios/:userId"
      exact
      component={ViewUserSurvey}
      layout={LayoutAuth}
    />

    <Route path="*" component={NotFound} isPrivate layout={LayoutAuth} />
  </Switch>
);

export default Routes;
