import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiArrowLeft, FiDatabase } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Box,
  Container,
  Flex,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import queryString from 'query-string';

import logoImg from '~/assets/logo-form.png';
import InputChakra from '~/shared/components/InputChakra';
import CheckboxInput from '~/shared/components/InputChakra/CheckboxChakra';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import { phoneMask } from '~/utils/mask';

interface User {
  id: number;
  name: string;
  email: string;
  phone: string;
  created_at: string;
  updated_at: string;
}

interface Company {
  name: string;
  branch_activity: string;
  number_branches: number;
  monthly_billing: string;
  responsibility: string;
  responsibility_id: string;
  tributary_regime: string;
  tributary_regime_id: string;
  address: string;
  number_employees: number;
}

interface OptionsSelect {
  id: number;
  name: string;
  created_at: string;
}

interface UserSurvey extends User {
  survey: {
    id: number;
    name: string;
    token: string;
    description: string;
    created_at: string;
    updated_at: string;
  };
  companies: Company[];
  user_reason: ReasonsChange;
}

interface ReasonsChange {
  id: number;
  name: string;
}

interface Filters {
  reasons_change: ReasonsChange[];
  tributary_regimes: OptionsSelect;
  responsibility: OptionsSelect;
}

interface Params {
  userId: string;
}

const ViewAccountingChanging: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const [userSurvey, setUserSurvey] = useState<UserSurvey>();
  const [users, setUsers] = useState<User>();
  const [company, setCompany] = useState<Company[]>([]);
  const history = useHistory();
  const [reasonsChangeOpt, setReasonsChangeOpt] = useState<ReasonsChange[]>([]);
  const [
    reasonsChangeSelected,
    setReasonsChangeSelected,
  ] = useState<ReasonsChange>();

  const { userId } = useParams<Params>();

  const location = useLocation();
  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const getData = useCallback(() => {
    setLoadingData(true);
    api
      .get<UserSurvey>(`/survey/accounting-change/${userId}`)
      .then((response) => {
        const { data } = response;
        const {
          companies,
          created_at,
          email,
          id,
          name,
          phone,
          survey,
          updated_at,
          user_reason,
        } = data;

        setUserSurvey(data);
        setUsers({
          created_at: data.created_at,
          email: data.email,
          id: data.id,
          name: data.name,
          phone: data.phone,
          updated_at: data.created_at,
        });

        setCompany(
          companies.map((cp) => {
            return {
              branch_activity: cp.branch_activity,
              name: cp.name,
              monthly_billing: cp.monthly_billing,
              number_branches: cp.number_branches,
              responsibility: cp.responsibility,
              responsibility_id: cp.responsibility_id,
              tributary_regime: cp.tributary_regime,
              tributary_regime_id: cp.tributary_regime_id,
              address: cp.address,
              number_employees: cp.number_employees,
            };
          }),
        );

        console.log(companies);

        setReasonsChangeSelected({
          id: user_reason.id,
          name: user_reason.name,
        });
      })
      .finally(() => setLoadingData(false));
  }, [userId]);

  const getFilters = useCallback(() => {
    setLoadingFilters(true);

    api
      .get<Filters>('/survey/accounting-change/filters')

      .then((response) => {
        const { data } = response;

        const { reasons_change, responsibility, tributary_regimes } = data;

        setReasonsChangeOpt(
          reasons_change.map((reasons) => {
            return { id: reasons.id, name: reasons.name };
          }),
        );
      })

      .finally(() => setLoadingFilters(false));
  }, []);

  useEffect(() => {
    getFilters();
    getData();

    // console.log(reasonsChangeOpt, companies);
  }, [getData, getFilters]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container pos="relative">
      {loadingFilters || loadingData ? (
        <LoadingAbsolute z_index={1111} min_height={500} />
      ) : (
        <>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="Voltar"
              icon={<FiArrowLeft />}
              size="lg"
              borderRadius="md"
              ml={3}
              mt={3}
              onClick={handleGoBack}
              border="2px solid #4e545e"
            />
            <Text ml={3} mt={3}>
              Voltar
            </Text>
          </Box>

          <Box marginTop="20px">
            <Image
              width="100%"
              objectFit="contain"
              src={logoImg}
              alt="MyIpac"
              mb={10}
            />
            <Flex justifyContent="center">
              <SectionHeader title={`Respondido por ${users?.name}`} />
            </Flex>
          </Box>

          <Form
            ref={formRef}
            onSubmit={() => ''}
            initialData={{
              reason_change_accounting: reasonsChangeSelected?.id.toString(),
            }}
          >
            <Box>
              {users && (
                <Container
                  backgroundColor="#fff"
                  border="1px solid rgb(218, 220, 224)"
                  borderRadius="8px"
                  position="relative"
                  paddingTop="22px"
                  padding="24px"
                  marginBottom="12px"
                  marginTop="12px"
                >
                  <Flex justifyContent="center">
                    <Text marginBottom="10px" fontSize="4xl">
                      Dados Pessoais
                    </Text>
                  </Flex>

                  <InputChakra
                    name="name"
                    label="Nome"
                    background="#e9ecef"
                    borderColor=""
                    mb="0px"
                    value={users.name}
                    readOnly
                  />

                  <InputChakra
                    name="email"
                    label="E-mail"
                    background="#e9ecef"
                    borderColor=""
                    mb="0px"
                    value={users.email}
                    readOnly
                  />

                  <InputChakra
                    name="phone"
                    label="Telefone"
                    background="#e9ecef"
                    borderColor=""
                    mb="0px"
                    value={phoneMask(users.phone)}
                    mask="phone"
                    readOnly
                  />
                </Container>
              )}

              <Box>
                {!!company?.length &&
                  company.map((comp, idx) => {
                    const num = idx + 1;
                    return (
                      <Fragment key={num}>
                        <Container
                          backgroundColor="#fff"
                          border="1px solid rgb(218,220,224)"
                          borderRadius="8px"
                          position="relative"
                          paddingTop="22px"
                          padding="24px"
                          marginBottom="12px"
                          marginTop="12px"
                        >
                          {idx === 0 && (
                            <Flex justifyContent="center">
                              <Text marginBottom="10px" fontSize="4xl">
                                Empresas
                              </Text>
                            </Flex>
                          )}

                          <Text
                            color="#718096"
                            display="flex"
                            flexDirection="row-reverse"
                          >
                            {`Empresa (${idx + 1})`}
                          </Text>

                          <InputChakra
                            name={`companies[${idx}].name`}
                            label="Nome da Empresa"
                            background="#e9ecef"
                            borderColor=""
                            mb="0px"
                            value={comp.name}
                            readOnly
                          />

                          <InputChakra
                            name={`companies[${idx}].branch_activity`}
                            label="Ramo da Atividade"
                            background="#e9ecef"
                            borderColor=""
                            mb="0px"
                            value={comp.branch_activity}
                            readOnly
                          />

                          <InputChakra
                            name={`companies[${idx}].address`}
                            label="Endereço"
                            background="#e9ecef"
                            borderColor=""
                            mb="0px"
                            value={comp.address}
                            readOnly
                          />

                          <SelectChakra
                            name={`companies[${idx}].tributary_regime_id`}
                            label="Regime Tributário"
                            background="#e9ecef"
                            borderColor=""
                            mb="0px"
                            options={
                              company?.length
                                ? company.map((cp) => {
                                    return {
                                      label: cp.tributary_regime,
                                      value: cp.tributary_regime_id,
                                    };
                                  })
                                : []
                            }
                            disabled
                          />

                          <InputChakra
                            name={`companies[${idx}].number_employees`}
                            label="Quantidade de Funcionários"
                            background="#e9ecef"
                            borderColor=""
                            mb="0px"
                            type="number"
                            value={comp.number_employees}
                            readOnly
                          />

                          <InputChakra
                            name={`companies[${idx}].number_branches`}
                            label="Quantidade de Filiais"
                            background="#e9ecef"
                            borderColor=""
                            mb="0px"
                            type="number"
                            value={comp.number_branches}
                            readOnly
                          />

                          <InputChakra
                            name={`companies[${idx}].monthly_billing`}
                            label="Faturamento Mensal"
                            background="#e9ecef"
                            borderColor=""
                            mb="0px"
                            value={comp.monthly_billing}
                            leftAddon="R$"
                            readOnly
                          />
                        </Container>
                      </Fragment>
                    );
                  })}

                {reasonsChangeOpt && (
                  <Container
                    backgroundColor="#fff"
                    border="1px solid rgb(218, 220, 224)"
                    borderRadius="8px"
                    position="relative"
                    paddingTop="22px"
                    padding="24px"
                    marginBottom="12px"
                    marginTop="12px"
                  >
                    <Flex justifyContent="center">
                      <Text fontSize="4xl" marginBottom="12px">
                        Motivo da mudança
                      </Text>
                    </Flex>
                    {reasonsChangeOpt.length ? (
                      <RadioChakra
                        name="reason_change_accounting"
                        options={reasonsChangeOpt.map((reasons) => {
                          return {
                            id: reasons.id.toString(),
                            value: reasons.id.toString(),
                            label: reasons.name,
                          };
                        })}
                      />
                    ) : (
                      <Text>
                        {users?.name} não precisa ou não quer mudar de
                        contabilidade.
                      </Text>
                    )}
                  </Container>
                )}
              </Box>
            </Box>
          </Form>
        </>
      )}
    </Container>
  );
};

export default ViewAccountingChanging;
