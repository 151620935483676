import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  Fragment,
  useMemo,
} from 'react';

import {
  Box,
  Text,
  Flex,
  RadioGroup,
  Stack,
  Radio,
  Container,
  Button,
  Image,
  Badge,
  Tag,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import logoImg from '~/assets/logo-form.png';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import SectionHeader from '~/shared/components/SectionHeader';
import MultiSelect from '~/shared/components/Select/MultiSelect';
import api from '~/shared/services/api';
import getValidationErrors, {
  getValidationErrorWithFocus,
} from '~/utils/getValidationErrors';

interface StructureFamily {
  name: string;
  relative_id: string;
  civil_status_id: string;
  age: string;
  visible?: boolean;
  id: number;
}

interface Properties {
  property_type_id: string;
  estimated_value: string;
  city: string;
  uf: string;
  owner_the_property: string;
  monthly_income_amount: string;
}

interface Company {
  name: string;
  branch_activity: string;
  tributary_regime_id: string;
  number_branches: string;
  monthly_billing: string;
  address: string;
  number_employees: string;
}

interface Personal {
  name: string;
  email: string;
  phone: string;
}

interface SurveyOptions {
  id: number;
  name: string;
  label: string;
  value: string;
  visible?: boolean;
}
interface DataForm {
  personal: Personal;
  structure_family: StructureFamily;
  properties: Properties;
  company: Company;
}

interface Survey {
  relativies: SurveyOptions[];
  civil_status: SurveyOptions[];
  property_types: SurveyOptions[];
  tributary_regimes: SurveyOptions[];
}

const Survey: React.FC = () => {
  const addToast = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [personal, setPersonal] = useState<Personal>();
  const [structureFamily, setStructureFamily] = useState<StructureFamily[]>([
    {
      name: '',
      relative_id: '',
      civil_status_id: '',
      age: '',
      visible: true,
      id: 1,
    },
  ]);
  const [properties, setProperties] = useState<Properties[]>([
    {
      property_type_id: '',
      city: '',
      monthly_income_amount: '',
      owner_the_property: '',
      uf: '',
      estimated_value: '',
    },
  ]);
  const [company, setCompany] = useState<Company[]>([
    {
      branch_activity: '',
      name: '',
      monthly_billing: '',
      number_branches: '',
      tributary_regime_id: '',
      address: '',
      number_employees: '',
    },
  ]);

  const [relativie, setRelativie] = useState<SurveyOptions[]>([]);
  const [civilStatus, setCivilStatus] = useState<SurveyOptions[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<SurveyOptions[]>([]);
  const [tributaryRegime, setTributaryRegime] = useState<SurveyOptions[]>([]);

  const estados = [
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AP', value: 'AP' },
    { label: 'AM', value: 'AM' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'DF', value: 'DF' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MS', value: 'MS' },
    { label: 'MT', value: 'MT' },
    { label: 'MG', value: 'MG' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PR', value: 'PR' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RS', value: 'RS' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'SC', value: 'SC' },
    { label: 'SP', value: 'SP' },
    { label: 'SE', value: 'SE' },
    { label: 'TO', value: 'TO' },
  ];

  const visibleFamilies = useMemo(() => {
    return structureFamily.filter((sf) => sf.visible);
  }, [structureFamily]);

  const [countFamily, setCountFamily] = useState(1);

  useEffect(() => {
    setLoading(true);
    api
      .get<Survey>(`/survey/filters`)
      .then((response) => {
        const {
          relativies,
          civil_status,
          property_types,
          tributary_regimes,
        } = response.data;
        setRelativie(
          relativies.map((rel) => {
            return { ...rel, label: rel.name, value: rel.id.toString() };
          }),
        );
        setCivilStatus(
          civil_status.map((cs) => {
            return { ...cs, label: cs.name, value: cs.id.toString() };
          }),
        );
        setPropertyTypes(
          property_types.map((pt) => {
            return { ...pt, label: pt.name, value: pt.id.toString() };
          }),
        );
        setTributaryRegime(
          tributary_regimes.map((tr) => {
            return { ...tr, label: tr.name, value: tr.id.toString() };
          }),
        );

        // console.log(tributaryRegime, relativie, civilStatus, propertyTypes);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleAddCompany = useCallback(() => {
    setCompany((state) => [
      ...state,
      {
        branch_activity: '',
        name: '',
        monthly_billing: '',
        number_branches: '',
        tributary_regime_id: '',
        address: '',
        number_employees: '',
      },
    ]);
  }, []);
  const handleRemoveCompany = useCallback(() => {
    setCompany((state) => state.filter((comp, i) => i !== company.length - 1));
  }, [company]);

  const handleAddfamily = useCallback(() => {
    setStructureFamily((state) => [
      ...state,
      {
        name: '',
        relative_id: '',
        civil_status_id: '',
        age: '',
        visible: true,
        id: countFamily + 1,
      },
    ]);
    setCountFamily((state) => state + 1);
    // console.log(countFamily, structureFamily);
  }, [countFamily]);

  const handleRemoveFamily = useCallback((/* id, */ idx) => {
    // setStructureFamily((state) => state.filter((family, i) => i !== idx));
    /* formRef.current?.clearField(`data[${idx}][name]`);
    formRef.current?.clearField(`data[${idx}][relative_id]`);
    formRef.current?.clearField(`data[${idx}][civil_status_id]`);
    formRef.current?.clearField(`data[${idx}][age]`);

    setStructureFamily((state) =>
      state.map((family, i) => {
        return {
          ...family,
          visible: family.id === id ? false : family.visible,
        };
      }),
    ); */

    formRef.current?.clearField(`data[${idx}][structure_family]`);
    setStructureFamily((state) => state.filter((st, i) => idx !== i));
  }, []);

  const handleAddProperties = useCallback(() => {
    setProperties((state) => [
      ...state,
      {
        property_type_id: '',
        city: '',
        monthly_income_amount: '',
        owner_the_property: '',
        uf: '',
        estimated_value: '',
      },
    ]);
  }, []);

  /* Apenas está deixando de exibir um card, pois os valores não estão sendo salvos no estado */
  const handleRemoveProperties = useCallback((idx: number) => {
    // console.log(idx);
    formRef.current?.clearField(`data[${idx}][proprietress]`);
    setProperties((state) => state.filter((ppt, i) => idx !== i));
  }, []);

  const handleSubmit = useCallback(
    async (dataSubmit: DataForm, { reset }) => {
      setLoadingSubmit(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string().required('E-mail obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
          structure_family: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required('Nome do parente obrigatório'),
                relative_id: Yup.string().required('Parentesco obrigatório'),
                civil_status_id: Yup.string().required(
                  'Estado Civil obrigatório',
                ),
                age: Yup.string().required('Idade do parente obrigatório'),
              }),
            )
            .required(),
          properties: Yup.array()
            .of(
              Yup.object().shape({
                owner_the_property: Yup.string().required(
                  'Nome do proprietário obrigatório',
                ),
                property_type_id: Yup.string().required(
                  'Tipo do imóvel obrigatório',
                ),
                estimated_value: Yup.string().required(
                  'Valor do imóvel obrigatório',
                ),
                city: Yup.string().required('Cidade obrigatório'),
                uf: Yup.string().required('UF obrigatório'),
                monthly_income_amount: Yup.string().required(
                  'Faturamento mensal do imóvel obrigatório',
                ),
              }),
            )
            .required(),

          companies: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Nome da empresa obrigatório'),
              branch_activity: Yup.string().required(
                'Ramo da atividade obrigatório',
              ),
              tributary_regime_id: Yup.string().required(
                'Regime tributário obrigatório',
              ),
              number_branches: Yup.string().required(
                'Quantidade de filiais obrigatório',
              ),
              monthly_billing: Yup.string().required(
                'Faturamento mensal da empresa obrigatório',
              ),
              address: Yup.string().required('Endereço da empresa obrigatório'),
              number_employees: Yup.string().required(
                'Quantidade de funcionários obrigatório',
              ),
            }),
          ),
        });

        await schema.validate(dataSubmit, {
          abortEarly: false,
        });

        await api.post<Survey>(`/survey/holding`, dataSubmit);

        // A partir daqui os dados já foram salvos corretamente
        reset();

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Formulário enviado!',
          description: `Formulário eviado com sucesso`,
          status: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrorWithFocus(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Erro ao tentar enviar formulário',
          description: err?.response?.data?.error || '',
          status: 'error',
        });
      } finally {
        setLoadingSubmit(false);
      }
    },
    [addToast],
  );

  return (
    <Container pos="relative">
      {loading && <LoadingAbsolute z_index={1111} min_height={500} />}
      {!loading && (
        <>
          <Box marginTop="20px">
            <Image
              width="100%"
              objectFit="contain"
              src={logoImg}
              alt="MyIPAC"
              mb={10}
            />
            <Flex justifyContent="center">
              <SectionHeader title="Estruturação de Holding" />
            </Flex>
          </Box>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Box>
              <Container
                backgroundColor="#fff"
                border="1px solid rgb(218,220,224)"
                borderRadius="8px"
                position="relative"
                paddingTop="22px"
                padding="24px"
                marginBottom="12px"
                marginTop="12px"
              >
                <Flex justifyContent="center">
                  <Text marginBottom="10px" fontSize="4xl">
                    Dados Pessoais
                  </Text>
                </Flex>

                <InputChakra name="survey_id" type="hidden" defaultValue={1} />

                <InputChakra
                  name="name"
                  label="Nome"
                  background="#e9ecef"
                  borderColor=""
                  mb="0px"
                  defaultValue={personal?.name}
                />

                <InputChakra
                  name="email"
                  label="E-mail"
                  background="#e9ecef"
                  borderColor=""
                  defaultValue={personal?.email}
                  mb="0px"
                />

                <InputChakra
                  name="phone"
                  label="Telefone"
                  background="#e9ecef"
                  borderColor=""
                  mb="0px"
                  mask="phone"
                  defaultValue={personal?.phone}
                />
              </Container>

              {!!structureFamily.length &&
                structureFamily.map((family, idx) => {
                  const num = idx + 1;
                  return (
                    <Box key={num} display={family.visible ? 'block' : 'none'}>
                      <Container
                        backgroundColor="#fff"
                        border="1px solid rgb(218,220,224)"
                        borderRadius="8px"
                        position="relative"
                        paddingTop="22px"
                        padding="24px"
                        marginBottom="12px"
                        marginTop="12px"
                      >
                        {idx === 0 && (
                          <Flex justifyContent="center">
                            <Text marginBottom="10px" fontSize="4xl">
                              Estrutura Familiar
                            </Text>
                          </Flex>
                        )}

                        <Text
                          color="#718096"
                          display="flex"
                          flexDirection="row-reverse"
                        >
                          {`Parente (${idx + 1})`}
                        </Text>

                        <InputChakra
                          name={`structure_family[${idx}].name`}
                          label="Nome"
                          defaultValue={family.name}
                          background="#e9ecef"
                          borderColor=""
                          mb="0px"
                        />

                        <SelectChakra
                          name={`structure_family[${idx}].relative_id`}
                          label="Parentesco"
                          background="#e9ecef"
                          borderColor=""
                          placeholder="Selecione"
                          defaultValue={family.relative_id}
                          mb="0px"
                          options={relativie}
                        />

                        <SelectChakra
                          name={`structure_family[${idx}].civil_status_id`}
                          label="Estado Civil"
                          background="#e9ecef"
                          borderColor=""
                          mb="0px"
                          options={civilStatus}
                          defaultValue={family.civil_status_id}
                          placeholder="Selecione"
                        />

                        <InputChakra
                          name={`structure_family[${idx}].age`}
                          label="Idade"
                          background="#e9ecef"
                          borderColor=""
                          defaultValue={family.age}
                          mb="0px"
                          type="number"
                        />
                        <Flex justifyContent="end">
                          {
                            /* family.id === countFamily &&  */ structureFamily.length -
                              1 ===
                              idx && (
                              <Button
                                onClick={handleAddfamily}
                                colorScheme="green"
                                variant="ghost"
                                marginTop="10px"
                              >
                                + Parente
                              </Button>
                            )
                          }
                          {
                            /* visibleFamilies.length > 1 && */ structureFamily.length -
                              1 ===
                              idx &&
                              structureFamily.length > 1 && (
                                <Button
                                  onClick={() => handleRemoveFamily(idx)}
                                  colorScheme="red"
                                  variant="ghost"
                                  marginTop="10px"
                                >
                                  Excluir
                                </Button>
                              )
                          }
                        </Flex>
                      </Container>
                    </Box>
                  );
                })}
            </Box>
            <Box>
              {properties.map((proper, idx) => {
                const num = idx + 1;
                return (
                  <Fragment key={num}>
                    <Container
                      backgroundColor="#fff"
                      border="1px solid rgb(218,220,224)"
                      borderRadius="8px"
                      position="relative"
                      paddingTop="22px"
                      padding="24px"
                      marginBottom="12px"
                      marginTop="12px"
                    >
                      {idx === 0 && (
                        <Flex justifyContent="center">
                          <Text fontSize="4xl" marginBottom="10px">
                            Imóveis
                          </Text>
                        </Flex>
                      )}

                      <Text
                        color="#718096"
                        display="flex"
                        flexDirection="row-reverse"
                      >
                        {`Imóvel (${idx + 1})`}
                      </Text>

                      <InputChakra
                        name={`properties[${idx}].owner_the_property`}
                        label="Proprietário do Bens (nome)"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        defaultValue={proper.owner_the_property}
                      />

                      <SelectChakra
                        name={`properties[${idx}].property_type_id`}
                        label="Descrição (tipo)"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        options={propertyTypes}
                        defaultValue={proper.property_type_id}
                        placeholder="Selecione"
                      />

                      <InputChakra
                        name={`properties[${idx}].estimated_value`}
                        label="Valor Estimado "
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        // type="number"
                        defaultValue={proper.estimated_value}
                        leftAddon="R$"
                        mask="money"
                        maxLength={15}
                      />
                      <InputChakra
                        name={`properties[${idx}].city`}
                        label="Localização (Cidade)"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        defaultValue={proper.city}
                      />
                      <SelectChakra
                        name={`properties[${idx}].uf`}
                        label="UF"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        options={estados}
                        placeholder="Selecione seu estado"
                        defaultValue={proper.uf}
                      />

                      <InputChakra
                        name={`properties[${idx}].monthly_income_amount`}
                        label="Faturamento Mensal"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        defaultValue={proper.monthly_income_amount}
                        leftAddon="R$"
                        mask="money"
                        maxLength={15}
                        // type="string"
                      />
                      <Flex justifyContent="end">
                        {properties.length - 1 === idx && (
                          <Button
                            onClick={handleAddProperties}
                            colorScheme="green"
                            variant="ghost"
                            marginTop="10px"
                          >
                            + Imóveis
                          </Button>
                        )}
                        {properties.length > 1 &&
                          properties.length - 1 === idx && (
                            <Button
                              onClick={() => handleRemoveProperties(idx)}
                              colorScheme="red"
                              variant="ghost"
                              marginTop="10px"
                            >
                              Excluir
                            </Button>
                          )}
                      </Flex>
                    </Container>
                  </Fragment>
                );
              })}
            </Box>
            <Box>
              {company.map((cp, idx) => {
                return (
                  <Fragment key={cp.name}>
                    <Container
                      backgroundColor="#fff"
                      border="1px solid rgb(218,220,224)"
                      borderRadius="8px"
                      position="relative"
                      paddingTop="22px"
                      padding="24px"
                      marginBottom="12px"
                      marginTop="12px"
                    >
                      {idx === 0 && (
                        <Flex justifyContent="center">
                          <Text fontSize="4xl" marginBottom="10px">
                            Empresas
                          </Text>
                        </Flex>
                      )}

                      <Text
                        color="#718096"
                        display="flex"
                        flexDirection="row-reverse"
                      >
                        {`Empresa (${idx + 1})`}
                      </Text>
                      <InputChakra
                        name={`companies[${idx}].name`}
                        label="Nome da Empresa"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        defaultValue={cp.name}
                      />
                      <InputChakra
                        name={`companies[${idx}].branch_activity`}
                        label="Ramo de Atividade"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        defaultValue={cp.branch_activity}
                      />

                      <InputChakra
                        name={`companies[${idx}].address`}
                        label="Endereço"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        defaultValue={cp.address}
                      />
                      <SelectChakra
                        name={`companies[${idx}].tributary_regime_id`}
                        label="Regime Tributário"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        options={tributaryRegime}
                        placeholder="Selecione"
                        defaultValue={cp.tributary_regime_id}
                      />

                      <InputChakra
                        name={`companies[${idx}].number_employees`}
                        label="Quantidade de Funcionários"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        type="number"
                        defaultValue={cp.number_employees}
                      />

                      <InputChakra
                        name={`companies[${idx}].number_branches`}
                        label="Quantidade de Filiais"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        type="number"
                        defaultValue={cp.number_branches}
                      />

                      <InputChakra
                        name={`companies[${idx}].monthly_billing`}
                        label="Faturamento Mensal"
                        background="#e9ecef"
                        borderColor=""
                        // type="string"
                        mb="0px"
                        defaultValue={cp.monthly_billing}
                        leftAddon="R$"
                        mask="money"
                        maxLength={15}
                      />
                      <Flex justifyContent="end">
                        {company.length - 1 === idx && (
                          <Button
                            onClick={handleAddCompany}
                            colorScheme="green"
                            variant="ghost"
                            marginTop="10px"
                          >
                            + Empresas
                          </Button>
                        )}{' '}
                        {company.length > 1 && company.length - 1 === idx && (
                          <Button
                            onClick={handleRemoveCompany}
                            colorScheme="red"
                            variant="ghost"
                            marginTop="10px"
                          >
                            Excluir
                          </Button>
                        )}
                      </Flex>
                    </Container>
                  </Fragment>
                );
              })}

              <Flex justifyContent="center">
                <Button
                  m={4}
                  colorScheme="blue"
                  width="150px"
                  height="50px"
                  onClick={() => formRef.current?.submitForm()}
                  isLoading={loadingSubmit}
                >
                  Enviar
                </Button>
              </Flex>
            </Box>
          </Form>
        </>
      )}
    </Container>
  );
};

export default Survey;
